<template>
    <v-card
        class="mb-12"
        
    >    
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pa-4 pt-6"
        >
            <v-container>
                <v-data-table
            ref="datatable"
            :headers="headers"
            :items="items"
            show-select
            :search="search"
            >           

            </v-data-table>
            </v-container>
        </v-form>        
    
    </v-card>

</template>

<script>


export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {

                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        },

        isEdit:{                
            required: true,
            default: false,
            type: Boolean
        },

        isVisible:{                
            required: true,
            default: false,
            type: Boolean
        }
    },

    components:{
        // AgGridVue,
             
    },

    data() {
        return {
            valid: false, 
            search: '',
            headers: [
                // { text: 'Num', align: 'start',sortable: false, value: 'index',},
                //{ text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', align: 'start',value: 'name',},
                { text: 'Anbauart', value: 'cultivation_species_caption' },
                { text: 'Fläche/ha', value: 'area_ha' },
                //{ text: 'Map',sortable: false, value: 'map' },
                //{ text: 'Aktiv', value: 'enabled' },
    
            ],   
                   
        }

    },

    computed:{
  
        fields(){
            return this.$store.state.app.fields

        },

        items(){
            return this.fields.items
        },

    },

    methods:{

 
 
    },

    watch: { 
  
        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal, this.item)
            if(this.isEdit)
                this.$refs.form.validate()
        },

        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal, this.item)
            this.state.valid = this.valid ; 
        }, 

        isEdit: function(newVal) { // watch it
            console.log('Prop changed: isEdit', newVal, this.item)
            if(!newVal)
                this.$refs.form.reset() 
        },    
        
        isVisible: function(newVal) { // watch it
            console.log('Prop changed: isVisible', newVal, this.item)
            // if(newVal)
            //     this.selectedType.type = this.item.type
            if(!this.isEdit)
                this.$refs.form.reset() 
        },          
    },

    mounted() {
        console.debug('mounted', this.item);
        if(this.isEdit)
                this.$refs.form.validate()
    }
}
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>


<style lang="scss">
.toolbar {
  height: 30px;
}

.grid-wrapper {
  flex: 2 2 0px;
  min-width: 0;
}

.drop-col {
  padding-left: 10px;
  box-sizing: border-box;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
}

.drop-target,
.tile {
  color: white;
}

.drop-target {
  border: 1px solid #888;
  background-color: #0077d0;
  padding: 10px;
}

.tile-container {
  box-sizing: border-box;
  border: 1px solid #888;
  background-color: #505050;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.tile {
  width: calc(50% - 30px);
  height: 60px;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

.green-row {
  background-color: #ddffdd !important;
}

.red-row {
  background-color: #ffdddd !important;
}

.blue-row {
  background-color: #ddddff !important;
}

.tile.green {
  background-color: darkseagreen;
}

.tile.red {
  background-color: indianred;
}

.tile.blue {
  background-color: cornflowerblue;
}
</style>